import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActionApiClientInterface, AuthService, CodeValueMessageArg, EnterpriseDataDto, Filter, FilterOperators, FindValuesOptions, MessageResourceManager, NtsBootService, ToastMessageService, ToastMessageType } from "@nts/std";
import { SingleAggregateApiClientInterface } from "@nts/std";
import { CreateApiClientInterface } from "@nts/std";
import { OnlineService } from "@nts/std";
import { EnterprisesListDto } from "@nts/std";
import { EnvironmentConfiguration } from "@nts/std/src/lib/environments";
import { FrameworkServiceApiClient } from "@nts/std";
import { LocalstorageHelper, LogService } from "@nts/std/src/lib/utility";
import { BehaviorSubject, delay, filter, firstValueFrom, map, merge, take } from "rxjs";
import { CreateExpenseAnnotationLongOpApiClient } from "src/app/create-expense-annotation-long-op/api-clients/create-expense-annotation-long-op.api-client";
import { ReceiptLongOpApiClient } from "src/app/receipt-long-op/api-clients/receipt-long-op.api-client";
import { UserAvailablePaymentsIdentity } from "src/app/user-available-payments/domain-models/user-available-payments.identity";
import { EnterpriseInformationDto } from "@nts/std";
import { UserAvailableExpensesIdentity } from "src/app/user-available-expenses/domain-models/user-available-expenses.identity";
import { EditExpenseAnnotationLongOpApiClient } from "src/app/edit-expense-annotation-long-op/api-clients/edit-expense-annotation-long-op.api-client";

export enum RequestType {
  FindValues = 0,
  GetMetaData = 1,
  UserCanAccessToService = 2,
  Create = 3,
  GetSingleAggregate = 4,
  GetSingleAggregateCached = 5,
  SyncAll = 6,
  Custom = 100,
}

export interface RequestData {
  type: RequestType,
  params: RequestDataParams;
  description?: string;
}

export interface RequestDataParams {
  apiClient?: ActionApiClientInterface;
  objectName?: string;

  /**
   * intervallo in secondi
   */
  syncInterval?: number;

  execute?: (forcedSync: boolean, defaultSyncInterval: number) => Promise<void>;
  outputProperties?: string[];
  storageKey?: string;
  elementPerPage?: number;
  findOptions?: FindValuesOptions;
  entityToLookUpFullName?: string;
  isEnterpriseBarrierRequired?: boolean;
}

export const requests = (
  receiptLongOpApiClient: ReceiptLongOpApiClient,
  createExpenseAnnotationLongOpApiClient: CreateExpenseAnnotationLongOpApiClient,
  editExpenseAnnotationLongOpApiClient: EditExpenseAnnotationLongOpApiClient,
  authService: AuthService,
  env: EnvironmentConfiguration
): RequestData[] => [
    {
      type: RequestType.GetMetaData,
      params: {
        apiClient: receiptLongOpApiClient,
      },
      description: MessageResourceManager.Current.getMessage('SyncStep_Receipts')
    },
    {
      type: RequestType.Create,
      params: {
        apiClient: receiptLongOpApiClient,
      },
      description: MessageResourceManager.Current.getMessage('SyncStep_Receipts')
    },
    {
      type: RequestType.UserCanAccessToService,
      params: {
        objectName: 'LayoutManager.UserLayoutDataObjects.Models.UserLayoutData'
      },
      description: MessageResourceManager.Current.getMessage('SyncStep_Layout')
    },
    {
      type: RequestType.UserCanAccessToService,
      params: {
        objectName: 'SecurityManager.SecurityDataObjects.Models.SecurityData'
      },
      description: MessageResourceManager.Current.getMessage('SyncStep_Security')
    },
    {
      type: RequestType.Custom,
      params: {
        execute: async (forcedSync: boolean, defaultSyncInterval: number) => {
          if (env.isEnterpriseBarrierRequired) {

            const tenantId: number = await authService.getTenantId();
            const enterpriseData: EnterpriseDataDto = await authService.getEnterpriseData(tenantId);
            if (!enterpriseData) {
              LogService.warn(MessageResourceManager.Current.getMessage('OfflineSyncEnterpriseDataNotFound'))
            }
            let enterpriseList: EnterprisesListDto = await authService.getEnterpriseList();
            if (!enterpriseList || enterpriseList?.enterprises?.length === 0) {
              LogService.warn(MessageResourceManager.Current.getMessage('OfflineSyncEnterpriseListNotFound'))
            }

            for (const enterprise of enterpriseList.enterprises) {


              const companies = enterprise.companies || [];

              let customHeaders = null;
              if (enterprise.enterpriseId > 0) {

                const enterpriseDataDto = new EnterpriseDataDto();
                enterpriseDataDto.enterpriseId = enterprise?.enterpriseId;
                enterpriseDataDto.companyId = enterprise?.companies?.length > 0 ? enterprise.companies[0].companyId : 0;
                customHeaders = new HttpHeaders().append(AuthService.ENTERPRISE_DATA_HEADER_NAME, JSON.stringify(enterpriseDataDto));
              }

              if (forcedSync) {
                await firstValueFrom(receiptLongOpApiClient.getAvailableModels(
                  {
                    bypass: false,
                    expirationTime: 1,
                    overrideBarrierValues: {
                      enterpriseBarrierValue: enterprise.enterpriseId,
                    }
                  },
                  null,
                  customHeaders
                ));
              } else {

                await firstValueFrom(receiptLongOpApiClient.getAvailableModels(
                  {
                    bypass: false,
                    force: true,
                    expirationTime: defaultSyncInterval,
                    overrideBarrierValues: {
                      enterpriseBarrierValue: enterprise.enterpriseId,
                    }
                  },
                  null,
                  customHeaders
                ));
              }
            }
          }
        },
      },
      description: MessageResourceManager.Current.getMessage('SyncStep_ExpenseModels')
    },

    // Utile se durante la sync forzata si vuole aggiornare anche i modelli nella memory cache per la create expense
    // Altrimenti è necessario fare F5 per allinerarli all'ultima versione
    {
      type: RequestType.Custom,
      params: {
        execute: async (forcedSync: boolean, defaultSyncInterval: number) => {
          if (env.isEnterpriseBarrierRequired) {

            const tenantId: number = await authService.getTenantId();
            const enterpriseData: EnterpriseDataDto = await authService.getEnterpriseData(tenantId);
            if (!enterpriseData) {
              LogService.warn(MessageResourceManager.Current.getMessage('OfflineSyncEnterpriseDataNotFound'))
            }
            let enterpriseList: EnterprisesListDto = await authService.getEnterpriseList();
            if (!enterpriseList || enterpriseList?.enterprises?.length === 0) {
              LogService.warn(MessageResourceManager.Current.getMessage('OfflineSyncEnterpriseListNotFound'))
            }

            for (const enterprise of enterpriseList.enterprises) {


              const companies = enterprise.companies || [];

              let customHeaders = null;
              if (enterprise.enterpriseId > 0) {

                const enterpriseDataDto = new EnterpriseDataDto();
                enterpriseDataDto.enterpriseId = enterprise?.enterpriseId;
                enterpriseDataDto.companyId = enterprise?.companies?.length > 0 ? enterprise.companies[0].companyId : 0;
                customHeaders = new HttpHeaders().append(AuthService.ENTERPRISE_DATA_HEADER_NAME, JSON.stringify(enterpriseDataDto));
              }

              if (forcedSync) {
                await firstValueFrom(createExpenseAnnotationLongOpApiClient.getAvailableModels(
                  {
                    bypass: false,
                    expirationTime: 1,
                    overrideBarrierValues: {
                      enterpriseBarrierValue: enterprise.enterpriseId,
                    }
                  },
                  null,
                  customHeaders
                ));
              } else {

                await firstValueFrom(createExpenseAnnotationLongOpApiClient.getAvailableModels(
                  {
                    bypass: false,
                    force: true,
                    expirationTime: defaultSyncInterval,
                    overrideBarrierValues: {
                      enterpriseBarrierValue: enterprise.enterpriseId,
                    }
                  },
                  null,
                  customHeaders
                ));
              }
            }
          }
        },
      },
      description: MessageResourceManager.Current.getMessage('SyncStep_ExpenseModels')
    },

    // Necessaria per recuperare la lista dei pagamenti personali ammessi
    {
      type: RequestType.Custom,
      params: {
        execute: async (forcedSync: boolean, defaultSyncInterval: number) => {

          if (env.isEnterpriseBarrierRequired) {

            const tenantId: number = await authService.getTenantId();
            const enterpriseData: EnterpriseDataDto = await authService.getEnterpriseData(tenantId);
            if (!enterpriseData) {
              LogService.warn(MessageResourceManager.Current.getMessage('OfflineSyncEnterpriseDataNotFound'))
            }
            let enterpriseList: EnterprisesListDto = await authService.getEnterpriseList();
            if (!enterpriseList || enterpriseList?.enterprises?.length === 0) {
              LogService.warn(MessageResourceManager.Current.getMessage('OfflineSyncEnterpriseListNotFound'))
            }

            for (const enterprise of enterpriseList.enterprises) {


              const companies = enterprise.companies || [];

              let customHeaders = null;
              if (enterprise.enterpriseId > 0) {

                const enterpriseDataDto = new EnterpriseDataDto();
                enterpriseDataDto.enterpriseId = enterprise?.enterpriseId;
                enterpriseDataDto.companyId = enterprise?.companies?.length > 0 ? enterprise.companies[0].companyId : 0;
                customHeaders = new HttpHeaders().append(AuthService.ENTERPRISE_DATA_HEADER_NAME, JSON.stringify(enterpriseDataDto));
              }

              for (const company of companies) {
                const paymentsIdentity = new UserAvailablePaymentsIdentity();
                paymentsIdentity.userId = await authService.getCurrentUserId();
                paymentsIdentity.companyId = company.companyId;

                const expensesIdentity = new UserAvailableExpensesIdentity();
                expensesIdentity.userId = await authService.getCurrentUserId();
                expensesIdentity.companyId = company.companyId;

                if (forcedSync) {
                  await firstValueFrom(receiptLongOpApiClient.getPersonalPayments(
                    paymentsIdentity,
                    {
                      bypass: false,
                      expirationTime: 1,
                      overrideBarrierValues: {
                        enterpriseBarrierValue: enterprise.enterpriseId,
                      }
                    },
                    customHeaders
                  ));

                  await firstValueFrom(receiptLongOpApiClient.getPersonalExpenses(
                    expensesIdentity,
                    {
                      bypass: false,
                      expirationTime: 1,
                      overrideBarrierValues: {
                        enterpriseBarrierValue: enterprise.enterpriseId,
                      }
                    },
                    customHeaders
                  ));
                } else {

                  await firstValueFrom(receiptLongOpApiClient.getPersonalPayments(
                    paymentsIdentity,
                    {
                      bypass: false,
                      force: true,
                      expirationTime: defaultSyncInterval,
                      overrideBarrierValues: {
                        enterpriseBarrierValue: enterprise.enterpriseId,
                      }
                    },
                    customHeaders
                  ));

                  await firstValueFrom(receiptLongOpApiClient.getPersonalExpenses(
                    expensesIdentity,
                    {
                      bypass: false,
                      force: true,
                      expirationTime: defaultSyncInterval,
                      overrideBarrierValues: {
                        enterpriseBarrierValue: enterprise.enterpriseId,
                      }
                    },
                    customHeaders
                  ));
                }
              }
            }
          }
        },
      },
      description: MessageResourceManager.Current.getMessage('SyncStep_Payments')
    },

    // Utile se durante la sync forzata si vuole aggiornare anche i modelli nella memory cache per la create expense
    // Altrimenti è necessario fare F5 per allinerarli all'ultima versione
    {
      type: RequestType.Custom,
      params: {
        execute: async (forcedSync: boolean, defaultSyncInterval: number) => {

          const tenantId: number = await authService.getTenantId();
          const enterpriseData: EnterpriseDataDto = await authService.getEnterpriseData(tenantId);
          if (!enterpriseData) {
            LogService.warn(MessageResourceManager.Current.getMessage('OfflineSyncEnterpriseDataNotFound'))
          }
          let enterpriseList: EnterprisesListDto = await authService.getEnterpriseList();
          if (!enterpriseList || enterpriseList?.enterprises?.length === 0) {
            LogService.warn(MessageResourceManager.Current.getMessage('OfflineSyncEnterpriseListNotFound'))
          }

          for (const enterprise of enterpriseList.enterprises) {


            let customHeaders = null;
            if (enterprise.enterpriseId > 0) {
              const enterpriseDataDto = new EnterpriseDataDto();
              enterpriseDataDto.enterpriseId = enterprise?.enterpriseId;
              enterpriseDataDto.companyId = enterprise?.companies?.length > 0 ? enterprise.companies[0].companyId : 0;
              customHeaders = new HttpHeaders().append(AuthService.ENTERPRISE_DATA_HEADER_NAME, JSON.stringify(enterpriseDataDto));
            }

            if (forcedSync) {
              await firstValueFrom(receiptLongOpApiClient.getClassificationLabelsAsync(
                {
                  bypass: false,
                  expirationTime: 1,
                  overrideBarrierValues: {
                    enterpriseBarrierValue: enterprise.enterpriseId,
                  }
                }
              ));

              await firstValueFrom(editExpenseAnnotationLongOpApiClient.getClassificationLabelsAsync(
                {
                  bypass: false,
                  expirationTime: 1,
                  overrideBarrierValues: {
                    enterpriseBarrierValue: enterprise.enterpriseId,
                  }
                }
              ));
            } else {

              await firstValueFrom(receiptLongOpApiClient.getClassificationLabelsAsync(
                {
                  bypass: false,
                  force: true,
                  expirationTime: defaultSyncInterval,
                  overrideBarrierValues: {
                    enterpriseBarrierValue: enterprise.enterpriseId,
                  }
                }
              ));
            }
          }

        },
      },
      description: MessageResourceManager.Current.getMessage('SyncStep_ExpenseNotes')
    },

    // Necessaria per recuperare i settings delle note spese
    {
      type: RequestType.Custom,
      params: {
        execute: async (forcedSync: boolean, defaultSyncInterval: number) => {
          if (env.isEnterpriseBarrierRequired) {

            const tenantId: number = await authService.getTenantId();
            const enterpriseData: EnterpriseDataDto = await authService.getEnterpriseData(tenantId);
            if (!enterpriseData) {
              LogService.warn(MessageResourceManager.Current.getMessage('OfflineSyncEnterpriseDataNotFound'))
            }
            let enterpriseList: EnterprisesListDto = await authService.getEnterpriseList();
            if (!enterpriseList || enterpriseList?.enterprises?.length === 0) {
              LogService.warn(MessageResourceManager.Current.getMessage('OfflineSyncEnterpriseListNotFound'))
            }

            for (const enterprise of enterpriseList.enterprises) {


              const companies = enterprise.companies || [];

              let customHeaders = null;
              if (enterprise.enterpriseId > 0) {

                const enterpriseDataDto = new EnterpriseDataDto();
                enterpriseDataDto.enterpriseId = enterprise?.enterpriseId;
                enterpriseDataDto.companyId = enterprise?.companies?.length > 0 ? enterprise.companies[0].companyId : 0;
                customHeaders = new HttpHeaders().append(AuthService.ENTERPRISE_DATA_HEADER_NAME, JSON.stringify(enterpriseDataDto));
              }


              if (forcedSync) {
                await firstValueFrom(createExpenseAnnotationLongOpApiClient.getWEASettingsData(
                  {
                    bypass: false,
                    expirationTime: 1,
                    overrideBarrierValues: {
                      enterpriseBarrierValue: enterprise.enterpriseId,
                    }
                  },
                  customHeaders
                ));
              } else {

                await firstValueFrom(createExpenseAnnotationLongOpApiClient.getWEASettingsData(
                  {
                    bypass: false,
                    force: true,
                    expirationTime: defaultSyncInterval,
                    overrideBarrierValues: {
                      enterpriseBarrierValue: enterprise.enterpriseId,
                    }
                  },
                  customHeaders
                ));
              }
            }
          }
        },
      },
      description: MessageResourceManager.Current.getMessage('SyncStep_Settings')
    },

    {
      type: RequestType.SyncAll,
      params: {
        objectName: 'CommissionMS.CommissionObjects.Models.Commission',
        // outputProperties: ['Id', 'Description'], // Default
        // elementPerPage: 199 // Default
        // syncInterval: (60 * 60 * 24) // Default 1 giorno
      },
      description: MessageResourceManager.Current.getMessage('SyncStep_Commissions')
    },
    {
      type: RequestType.SyncAll,
      params: {
        objectName: 'Subject.LeadObjects.Models.Lead',
        outputProperties: ['Id', 'CompanyName'],
      },
      description: MessageResourceManager.Current.getMessage('SyncStep_Leads')
    },
    {
      type: RequestType.SyncAll,
      params: {
        objectName: 'Subject.CustomerObjects.Models.Customer',
        outputProperties: ['Id', 'CompanyName'],
      },
      description: MessageResourceManager.Current.getMessage('SyncStep_Customers')
    }
  ];

@Injectable()
export class OfflineSyncService {

  private requests: RequestData[] = [];

  /**
   * intervallo in secondi, default 1 giorno
   */
  private syncInterval: number = (60 * 60 * 24); // 1 giorno

  syncInProgress$ = new BehaviorSubject<boolean>(false);
  syncStatusText$ = new BehaviorSubject<string>('');
  isFirstSync$ = new BehaviorSubject<boolean>(false);
  isForcedSync$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly apiClient: FrameworkServiceApiClient,
    private readonly onlineService: OnlineService,
    private readonly toastMessageService: ToastMessageService,
    private readonly authService: AuthService,
    private readonly env: EnvironmentConfiguration
  ) { }

  async init(
    bootService: NtsBootService,
    receiptLongOpApiClient: ReceiptLongOpApiClient,
    createExpenseAnnotationLongOpApiClient: CreateExpenseAnnotationLongOpApiClient,
    editExpenseAnnotationLongOpApiClient: EditExpenseAnnotationLongOpApiClient,
  ) {

    const firstSync = await LocalstorageHelper.getStorageItem('firstOffLineSyncCompleted', null, true, false) !== true;
    this.isFirstSync$.next(firstSync);

    if (this.onlineService.isOnline === false || window.location.href.indexOf("disable-sync=true") > -1) {
      return;
    }

    const tenantId: number = await this.authService.getTenantId();
    let enterpriseData: EnterpriseDataDto = await this.authService.getEnterpriseData(tenantId);
    if (!enterpriseData) {
      enterpriseData = await firstValueFrom(this.authService.onEnterpriseDataUpdate.pipe(
        filter(((enterpriseData: EnterpriseDataDto) => enterpriseData != null)),
        take(1)
      ))
    }

    // Recupero i settings e aggiorno la sua cache
    const weaSettingsResponse = await firstValueFrom(createExpenseAnnotationLongOpApiClient.getWEASettingsData({
      bypass: false,
      expirationTime: 1
    }))

    // Utilizzo il risultato dei settings per impostare l'intervallo per la sync
    // Se la chiamata non va a buon fine usa l'intervallo di default
    if (weaSettingsResponse.operationSuccedeed && weaSettingsResponse.result?.sinchronizationFrequency > 0) {
      // sinchronizationFrequency è espresso in ore
      // per usarlo in setSyncInterval trasformo il risultato in secondi
      this.setSyncInterval(weaSettingsResponse.result.sinchronizationFrequency * 60 * 60)
    }

    this.setRequests(requests(
      receiptLongOpApiClient,
      createExpenseAnnotationLongOpApiClient,
      editExpenseAnnotationLongOpApiClient,
      bootService.authService,
      bootService.environmentConfiguration
    ))

    await this.sync(false, this.isFirstSync$.value ? 50 : 500);
  }

  /**
   * Imposta i secondi di intervallo tra ogni sync
   * @param syncInterval secondi
   */
  setSyncInterval(syncInterval: number) {
    this.syncInterval = syncInterval;
  }

  async setRequests(requests: any[]) {
    this.requests = requests;
  }

  async sync(forcedSync = false, delayRequestTime = 100) {

    this.isForcedSync$.next(forcedSync);
    if (this.syncInProgress$.value) {
      LogService.warn('Sync in progress!');
      this.toastMessageService.showToast({
        message: MessageResourceManager.Current.getMessage('SyncAlreadyInProgressMessage'),
        title: MessageResourceManager.Current.getMessage('SyncAlreadyInProgressTitle'),
        type: ToastMessageType.warn,
      })
      return;
    }

    this.syncInProgress$.next(true);

    // TODO
    // verifica l'ultima esecuzione controllando il local storage

    // se è passato meno tempo la eseguo subito

    // avvio il timer

    // const timeInterval = 1000 * 60 * 5;  // 5 minutes
    // timer(0, timeInterval).pipe(switchMap(_ => this.fetchSomeData()))


    if (this.onlineService.isOnline === false) {
      this.syncInProgress$.next(false);
      return;
    }

    const accessToken = await this.authService.getAccessToken();

    if (!accessToken) {
      const tokenRenewed = await firstValueFrom(
        merge(
          this.authService.onSessionRefreshingError.pipe(map(_ => false)),
          this.authService.onAccessTokenChanged.pipe(map(_ => true)),
        ).pipe(
          take(1), map((success) => success)
        )
      );
      if (!tokenRenewed) {
        this.syncInProgress$.next(false);
        return;
      }
    }

    this.syncStatusText$.next(MessageResourceManager.Current.getMessage('SyncStep_DefaultMessage'));
    const syncInProgressCompletedTaskIndex = await LocalstorageHelper.getStorageItem('SyncInProgressCompletedTaskIndex', null, true, false);

    for (const [key, req] of this.requests.entries()) {
      if (syncInProgressCompletedTaskIndex != null && parseInt(syncInProgressCompletedTaskIndex?.toString()) >= key) {
        continue
      }
      await LocalstorageHelper.setStorageItem('SyncInProgressCompletedTaskIndex', key - 1, null, true, false);

      const message = MessageResourceManager.Current.getMessageWithArgs(
        'SyncStep_DefaultMessageWithCurrentObject',
        [
          new CodeValueMessageArg('CurrentObject', req.description),
          new CodeValueMessageArg('CurrentStepNumber', (key + 1).toString()),
          new CodeValueMessageArg('TotalStepNumber', this.requests.length.toString()),
        ]
      );

      this.syncStatusText$.next(
        message
      )
      switch (req.type) {
        case RequestType.FindValues:
          const findValuesSyncInterval = req.params?.syncInterval || this.syncInterval;
          await firstValueFrom(
            this.apiClient.findValues(
              null,                                   // entityToLookUp
              req.params.findOptions,                 // findOptions
              req.params.entityToLookUpFullName,      // entityToLookUpFullName
              null,                                   // rootModelName
              {
                bypass: false,
                expirationTime: forcedSync ? 1 : findValuesSyncInterval,
              }
            ).pipe(delay(delayRequestTime))
          )
          break;

        case RequestType.GetMetaData:
          const getMetaDataSyncInterval = req.params?.syncInterval || this.syncInterval;
          await firstValueFrom(
            req.params.apiClient.getMetaDataAsync(
              false,                                      // includeDescriptions,
              {
                bypass: false,
                expirationTime: forcedSync ? 1 : getMetaDataSyncInterval,
                force: false,
              }
            ).pipe(delay(delayRequestTime))
          );
          break;

        case RequestType.Create:

          const createSyncInterval = req.params?.syncInterval || this.syncInterval;

          if (this.env.isEnterpriseBarrierRequired) {

            const tenantId: number = await this.authService.getTenantId();
            const enterpriseData: EnterpriseDataDto = await this.authService.getEnterpriseData(tenantId);
            if (!enterpriseData) {
              LogService.warn(MessageResourceManager.Current.getMessage('OfflineSyncEnterpriseDataNotFound'))
            }
            let enterpriseList: EnterprisesListDto = await this.authService.getEnterpriseList();
            if (!enterpriseList || enterpriseList?.enterprises?.length === 0) {
              LogService.warn(MessageResourceManager.Current.getMessage('OfflineSyncEnterpriseListNotFound'))
            }

            for (const enterprise of enterpriseList.enterprises) {
              let customHeaders = null;
              if (enterprise.enterpriseId > 0) {

                const enterpriseDataDto = new EnterpriseDataDto();
                enterpriseDataDto.enterpriseId = enterprise?.enterpriseId;
                enterpriseDataDto.companyId = enterprise?.companies?.length > 0 ? enterprise.companies[0].companyId : 0;
                customHeaders = new HttpHeaders().append(AuthService.ENTERPRISE_DATA_HEADER_NAME, JSON.stringify(enterpriseDataDto));
              }

              await firstValueFrom(
                ((req.params.apiClient as any) as CreateApiClientInterface)
                  .create(
                    {
                      bypass: false,
                      expirationTime: forcedSync ? 1 : createSyncInterval,
                      force: false,
                      overrideBarrierValues: {
                        enterpriseBarrierValue: enterprise.enterpriseId,
                      }
                    },
                    customHeaders
                  ).pipe(delay(delayRequestTime))
              )
            }

          } else {
            await firstValueFrom(
              ((req.params.apiClient as any) as CreateApiClientInterface).create({
                bypass: false,
                expirationTime: forcedSync ? 1 : createSyncInterval,
                force: false,
              }).pipe(delay(delayRequestTime))
            )
          }

          break;

        case RequestType.GetSingleAggregate:
          const getSingleAggregateSyncInterval = req.params?.syncInterval || this.syncInterval;
          await firstValueFrom(
            (req.params.apiClient as SingleAggregateApiClientInterface).getSingleAggregate(
              {
                bypass: false,
                expirationTime: forcedSync ? 1 : getSingleAggregateSyncInterval,
                force: false,
              }
            ).pipe(delay(delayRequestTime))
          )
          break;

        case RequestType.GetSingleAggregateCached:
          const getSingleAggregateCachedSyncInterval = req.params?.syncInterval || this.syncInterval;
          await firstValueFrom(
            (req.params.apiClient as SingleAggregateApiClientInterface).getSingleAggregateCached(
              {
                bypass: false,
                expirationTime: forcedSync ? 1 : getSingleAggregateCachedSyncInterval,
                force: false,
              }
            ).pipe(delay(delayRequestTime))
          )
          break;

        case RequestType.UserCanAccessToService:
          const userCanAccessToServiceSyncInterval = req.params?.syncInterval || this.syncInterval;
          await firstValueFrom(
            this.apiClient.userCanAccessToService(
              req.params.objectName,
              {
                bypass: false,
                expirationTime: forcedSync ? 1 : userCanAccessToServiceSyncInterval,
                force: false,
              }
            ).pipe(delay(delayRequestTime))
          )
          break;

        case RequestType.SyncAll:

          req.params.isEnterpriseBarrierRequired = req.params.isEnterpriseBarrierRequired ?? this.env.isEnterpriseBarrierRequired;
          const tenantId: number = await this.authService.getTenantId();
          const enterpriseData: EnterpriseDataDto = await this.authService.getEnterpriseData(tenantId);
          if (!enterpriseData) {
            LogService.warn(MessageResourceManager.Current.getMessage('OfflineSyncEnterpriseDataNotFound'))
          }
          let enterpriseList: EnterprisesListDto = await this.authService.getEnterpriseList();
          if (!enterpriseList || enterpriseList?.enterprises?.length === 0) {
            LogService.warn(MessageResourceManager.Current.getMessage('OfflineSyncEnterpriseListNotFound'))
          }

          if (!req.params.isEnterpriseBarrierRequired || enterpriseList?.enterprises?.length == 0) {
            enterpriseList = new EnterprisesListDto();
            const blankEnterprise = new EnterpriseInformationDto();
            blankEnterprise.enterpriseId = 0;
            enterpriseList.enterprises = [blankEnterprise];
          }

          for (const enterprise of enterpriseList.enterprises) {
            let customHeaders = null;
            if (enterprise.enterpriseId > 0) {

              const enterpriseDataDto = new EnterpriseDataDto();
              enterpriseDataDto.enterpriseId = enterprise?.enterpriseId;
              enterpriseDataDto.companyId = enterprise?.companies?.length > 0 ? enterprise.companies[0].companyId : 0;
              customHeaders = new HttpHeaders().append(AuthService.ENTERPRISE_DATA_HEADER_NAME, JSON.stringify(enterpriseDataDto));
            }

            const elements = [];
            const findValuesOptions = new FindValuesOptions();
            const elementPerPage = req.params?.elementPerPage || 199;
            const syncAllSyncInterval = req.params?.syncInterval || this.syncInterval;
            findValuesOptions.outputProperties = req.params?.outputProperties || ['Id', 'Description']
            findValuesOptions.skip = 0
            findValuesOptions.take = elementPerPage + 1;
            let continueSync = true;
            const entityToLookUpFullName = req.params.objectName;


            let oldData: {
              lastSync: number,
              elements: any[]
            } = await LocalstorageHelper.getStorageItem(`${req.params?.storageKey || req.params.objectName}`, null, true, true, false, {
              enterpriseBarrierValue: enterprise.enterpriseId > 0 ? enterprise.enterpriseId : undefined
            }) as {
              lastSync: number,
              elements: any[]
            };

            if (!oldData || oldData.elements == null) {
              oldData = {
                lastSync: 0,
                elements: []
              }
            }

            const now = Math.floor(new Date().getTime() / 1000);

            if (!forcedSync && oldData?.lastSync > 0 && now - oldData.lastSync <= syncAllSyncInterval) {
              // Non è passato abbastanza tempo
            } else {
              if (req.params.isEnterpriseBarrierRequired) {

                const defaultFilter = [...findValuesOptions.filters];

                findValuesOptions.outputProperties.push('CompanyId');

                for (const company of enterprise?.companies) {

                  const companyIdFilter = new Filter();
                  companyIdFilter.name = 'CompanyId';
                  companyIdFilter.operator = FilterOperators.Equals;
                  companyIdFilter.value = company.companyId;
                  findValuesOptions.filters = [...defaultFilter, companyIdFilter]

                  do {

                    const result = await firstValueFrom(
                      this.apiClient.findValues(
                        null,
                        findValuesOptions,
                        entityToLookUpFullName,
                        null,
                        {
                          bypass: true, // bypass cache
                        },
                        null,
                        customHeaders
                      ).pipe(delay(delayRequestTime))
                    );
                    if (result.operationSuccedeed && result.result?.length > 0) {
                      if (result.result?.length > elementPerPage) {
                        elements.push(...result.result.slice(0, -1))
                      } else {
                        elements.push(...result.result);
                      }
                    }

                    findValuesOptions.skip = findValuesOptions.skip + elementPerPage;
                    continueSync = result.operationSuccedeed && result.result?.length > elementPerPage;
                  } while (continueSync);

                }

              } else {

                do {

                  const result = await firstValueFrom(
                    this.apiClient.findValues(
                      null,
                      findValuesOptions,
                      entityToLookUpFullName,
                      null,
                      {
                        bypass: true, // bypass cache
                      },
                      null,
                      customHeaders
                    ).pipe(delay(delayRequestTime))
                  );
                  if (result.operationSuccedeed && result.result?.length > 0) {
                    if (result.result?.length > elementPerPage) {
                      elements.push(...result.result.slice(0, -1))
                    } else {
                      elements.push(...result.result);
                    }
                  }

                  findValuesOptions.skip = findValuesOptions.skip + elementPerPage;
                  continueSync = result.operationSuccedeed && result.result?.length > elementPerPage;
                } while (continueSync);
              }

              LocalstorageHelper.setStorageItem(`${req.params?.storageKey || req.params.objectName}`, {
                lastSync: Math.floor(new Date().getTime() / 1000),
                elements
              }, null, true, true, false, {
                enterpriseBarrierValue: enterprise.enterpriseId > 0 ? enterprise.enterpriseId : undefined
              })
            }

          }
          break;

        case RequestType.Custom:
          await req.params.execute(forcedSync, this.syncInterval);
          break;

        default:
          break;

      }
    }
    await LocalstorageHelper.removeStorageItem('SyncInProgressCompletedTaskIndex', null, true, false);
    this.syncInProgress$.next(false);

    if (forcedSync) {
      this.toastMessageService.showToast({
        message: MessageResourceManager.Current.getMessage('SyncEndedMessage'),
        title: MessageResourceManager.Current.getMessage('SyncEndedTitle'),
        type: ToastMessageType.info,
      })
    } else if (this.isFirstSync$.value) {
      this.toastMessageService.showToast({
        message: MessageResourceManager.Current.getMessage('FirstSyncEndedWithOfflineSupportMessage'),
        title: MessageResourceManager.Current.getMessage('FirstSyncEndedWithOfflineSupportTitle'),
        type: ToastMessageType.info,
      })
    }

    await LocalstorageHelper.setStorageItem('firstOffLineSyncCompleted', true, null, true, false);
    this.isForcedSync$.next(false);
  }
}
