import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { NotFoundComponent, BaseRoutes, MainContainerComponent } from '@nts/std';
import { environment } from 'src/environments/environment';
import { ReceiptListModule } from './receipt-list/receipt-list.module';
import { ReceiptLongOpModule } from './receipt-long-op/receipt-long-op.module';
import { CLASSIFICATION_LABELS_SLUG, CREATE_EXPENSE_ANNOTATION_LONG_OP_SLUG, EDIT_EXPENSE_ANNOTATION_LONG_OP_SLUG, EXPENSE_ANNOTATION_ALERT_SLUG, EXPENSE_ANNOTATION_LIST_LONG_OP_SLUG, EXPENSE_MODEL_SLUG, EXPENSE_TYPE_SLUG, PRINT_EXPENSE_ANNOTATION_SLUG, RECEIPT_LIST_SLUG, RECEIPT_LONG_OP_SLUG, USER_AVAILABLE_EXPENSES_SLUG, USER_AVAILABLE_PAYMENTS_SLUG, USER_CASH_ADVANCE_LONG_OP_SLUG, USER_CASH_ADVANCE_SLUG, USER_EXPENSE_MODEL_ASSOCIATION_SLUG, WEA_SETTINGS_SLUG } from './shared/shared.module';
const title = environment ? environment.appTitle : 'Application Title';

const routes: Routes = [

  // #region rotte con autenticazione
  ...BaseRoutes.getBaseAuthentictedRoutes(
    environment,
    [
      ...BaseRoutes.getEntityRoutes(
        title,
        RECEIPT_LONG_OP_SLUG,
        // Removed lazy load for offline
        () => ReceiptLongOpModule
      ),

      ...BaseRoutes.getEntityRoutes(
        title,
        RECEIPT_LIST_SLUG,
        // Removed lazy load for offline
        // https://stackoverflow.com/questions/45447069/routing-to-sub-routing-module-without-lazy-loading
        // da verificare se nelle build con aot a true va in errore
        // nel caso sarà necessario importare il modulo e qui dichiare direttamente le rotte esportate nel modulo
        () => ReceiptListModule
      ),

      ...BaseRoutes.getEntityRoutes(
        title,
        CREATE_EXPENSE_ANNOTATION_LONG_OP_SLUG,
        () => import('./create-expense-annotation-long-op/create-expense-annotation-long-op.module').then(m => m.CreateExpenseAnnotationLongOpModule)
      ),

      ...BaseRoutes.getEntityRoutes(
        title,
        EXPENSE_TYPE_SLUG,
        () => import('./expense-type/expense-type.module').then(m => m.ExpenseTypeModule)
      ),

      ...BaseRoutes.getEntityRoutes(
        title,
        USER_EXPENSE_MODEL_ASSOCIATION_SLUG,
        () => import('./user-expense-model-association/user-expense-model-association.module').then(m => m.UserExpenseModelAssociationModule)
      ),

      ...BaseRoutes.getEntityRoutes(
        title,
        USER_CASH_ADVANCE_SLUG,
        () => import('./user-cash-advance/user-cash-advance.module').then(m => m.UserCashAdvanceModule)
      ),

      ...BaseRoutes.getEntityRoutes(
        title,
        EXPENSE_MODEL_SLUG,
        () => import('./expense-model/expense-model.module').then(m => m.ExpenseModelModule)
      ),

      ...BaseRoutes.getEntityRoutes(
        title,
        EXPENSE_ANNOTATION_LIST_LONG_OP_SLUG,
        () => import('./expense-annotation-list-long-op/expense-annotation-list-long-op.module').then(m => m.ExpenseAnnotationListLongOpModule)
      ),

      ...BaseRoutes.getEntityRoutes(
        title,
        EDIT_EXPENSE_ANNOTATION_LONG_OP_SLUG,
        () => import('./edit-expense-annotation-long-op/edit-expense-annotation-long-op.module').then(m => m.EditExpenseAnnotationLongOpModule)
      ),

      ...BaseRoutes.getEntityRoutes(
        title,
        USER_CASH_ADVANCE_LONG_OP_SLUG,
        () => import('./user-cash-advance-long-op/user-cash-advance-long-op.module').then(m => m.UserCashAdvanceLongOpModule)
      ),

      ...BaseRoutes.getEntityRoutes(
        title,
        WEA_SETTINGS_SLUG,
        () => import('./wea-settings/wea-settings.module').then(m => m.WEASettingsModule)
      ),

      ...BaseRoutes.getEntityRoutes(
        title,
        USER_AVAILABLE_EXPENSES_SLUG,
        () => import('./user-available-expenses/user-available-expenses.module').then(m => m.UserAvailableExpensesModule)
      ),

      ...BaseRoutes.getEntityRoutes(
        title,
        USER_AVAILABLE_PAYMENTS_SLUG,
        () => import('./user-available-payments/user-available-payments.module').then(m => m.UserAvailablePaymentsModule)
      ),

      ...BaseRoutes.getEntityRoutes(
        title,
        PRINT_EXPENSE_ANNOTATION_SLUG,
        () => import('./print-expense-annotation/print-expense-annotation.module').then(m => m.PrintExpenseAnnotationModule)
      ),

      ...BaseRoutes.getEntityRoutes(
        title,
        CLASSIFICATION_LABELS_SLUG,
        () => import('./classification-labels/classification-labels.module').then(m => m.ClassificationLabelsModule)
      ),

      ...BaseRoutes.getEntityRoutes(
        title,
        EXPENSE_ANNOTATION_ALERT_SLUG,
        () => import('./expense-annotation-alert/expense-annotation-alert.module').then(m => m.ExpenseAnnotationAlertModule)
      ),

      ...BaseRoutes.getDefaultRoutes(
        title,
        environment,
        true
      ),
    ],
    () => import('./shared/components/main-container-with-animation/main-container-with-animation.component').then(mod => mod.MainContainerWithAnimationComponent)
  ),
  // #endregion rotte con autenticazione

  // rotte base per la gestione degli errori
  ...BaseRoutes.getBaseErrorsRoute()
];

@NgModule({
  imports: [
    // TODO Tommy: si potrebbe creare una custom preloadin strategy per precaricare solo le rotte offline
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', paramsInheritanceStrategy: 'always', preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
