<div class="form-page-container">
  <div class="header-buttons">
    <nts-text-button class="close-command" [icon]="'cancel'" (onClicked)="onExit()"></nts-text-button>
  </div>
  <div class="step-page-container">
    <nts-loader *ngIf="stepLoading" [isVisible]="true" class="loader-blank" [animation]="true" [skipHeader]="false">
    </nts-loader>
    <div class="form-step" [@routeAnimations]="getRouteAnimationData()">
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
  </div>
  <div *ngIf="currentViewModel?.hasActionsButtons | async" class="actions-container">
    <nts-text-button [label]="'CMD_Back_DisplayName'|NTSTranslate" (onClicked)="onPreviousStep()"
      [isDisabled]="currentViewModel?.isPreviousDisabled | async">
    </nts-text-button>
    <nts-filled-button [label]="forwordButtonLabel" (onClicked)="onNextStep()"
      *ngIf="!(currentViewModel?.hideNext | async)" [isDisabled]="stepLoading">
    </nts-filled-button>
  </div>
</div>
